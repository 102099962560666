<template>
    <div>
        <h3>Menu Management</h3>

        <v-btn
                text
                :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
                outlined
                class="btn btn-lg btn-secondary-main"
                style="background:transparent;"
        >
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
        <div class="row mt-2">
            <div class="col-xl-12">
                <KTPortlet v-bind:title="''">
                    <template v-slot:body>
                        <div class="row">
                            <div class="col-md-4">
                                <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
                                    <v-tab>Header</v-tab>
                                    <v-tab>Footer</v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item key="Header">
                                        <v-card color="basil" flat>
                                            <v-card-text>
                                                <VueNestable
                                                        @change="sortNow(header_menu.id, nestableHeaderItems)"
                                                        v-model="nestableHeaderItems"
                                                        :max-depth="2"
                                                        id-prop="id"
                                                        children-prop="nested"
                                                >
                                                    <template slot-scope="{ item,index }">
                                                        <!-- Handler -->
                                                        <VueNestableHandle :item="item">
                                                            <i class="fas fa-bars"/>
                                                        </VueNestableHandle>

                                                        <!-- Content -->
                                                        <span>{{ item.title }}</span>
                                                        <span class="btn-group" v-if="!item.deleted_at">
                                                          <a href="#" class @click.prevent="edit(item)">
                                                            <img src="@/assets/media/icons/edit-tools.png" class="edit_icon"/>
                                                          </a>
                                                          <a href="#" @click.prevent="deleteImg(header_menu.id, item.id)">
                                                            <img
                                                                    src="@/assets/media/icons/send-to-trash.png"
                                                                    class="delete_icon"
                                                            />
                                                          </a>
                                                        </span>
                                                                                    <span class="btn-group" v-else>
                                                          <a
                                                                  href="#"
                                                                  class
                                                                  @click.prevent="restoreItem(header_menu.id, item)"
                                                                  title="Restore"
                                                          >
                                                            <img
                                                                    src="@/assets/media/icons/svg/Navigation/Arrow-left.svg"
                                                                    class="delete_icon"
                                                            />
                                                          </a>
                                                         </span>
                                                    </template>
                                                </VueNestable>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <v-tab-item key="Footer">
                                        <v-card color="basil" flat>
                                            <v-card-text>
                                                <VueNestable
                                                        @change="sortNow(footer_menu.id, nestableFooterItems)"
                                                        v-model="nestableFooterItems"
                                                        :max-depth="2"
                                                        id-prop="id"
                                                        children-prop="nested"
                                                >
                                                    <template slot-scope="{ item,index }">
                                                        <!-- Handler -->
                                                        <VueNestableHandle :item="item">
                                                            <i class="fas fa-bars"/>
                                                        </VueNestableHandle>

                                                        <!-- Content -->
                                                        <span>{{ item.title }}</span>
                                                        <span class="btn-group" v-if="!item.deleted_at">
                              <a href="#" class @click.prevent="edit(item)">
                                <img src="@/assets/media/icons/edit-tools.png" class="edit_icon"/>
                              </a>
                              <a href="#" @click.prevent="deleteImg(footer_menu.id,item.id)">
                                <img
                                        src="@/assets/media/icons/send-to-trash.png"
                                        class="delete_icon"
                                />
                              </a>
                            </span>
                                                        <span class="btn-group" v-else>
                              <a
                                      href="#"
                                      class
                                      @click.prevent="restoreItem(footer_menu.id, item)"
                                      title="Restore"
                              >
                                <img
                                        src="@/assets/media/icons/svg/Navigation/Arrow-left.svg"
                                        class="delete_icon"
                                />
                              </a>
                            </span>
                                                    </template>
                                                </VueNestable>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs-items>
                            </div>
                            <div class="col-md-8">
                                <v-app>
                                    <v-card raised v-if="selectedMenu!=null">
                                        <v-card-title>Edit Menu</v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-list two-line>
                                                        <v-list-item-group v-model="editSelected"
                                                                           active-class="active">
                                                            <template v-for="(item, index) in options">
                                                                <v-list-item :key="index"
                                                                             @click="changeSelectedType(item.value)">
                                                                    <template v-slot:default="{  }">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title
                                                                                    v-text="item.title"></v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </template>
                                                                </v-list-item>

                                                                <!-- <v-divider v-if="index + 1 < options.length" :key="index"></v-divider> -->
                                                            </template>
                                                        </v-list-item-group>
                                                    </v-list>
                                                </v-col>
                                                <v-col cols="8">
                                                    <v-select
                                                            v-if="editSelected==0"
                                                            :items="pages"
                                                            item-text="title"
                                                            item-value="id"
                                                            v-model="selectedMenu.page_id"
                                                            label="Select Page to add"
                                                            outlined
                                                            densed
                                                    ></v-select>
                                                    <v-select
                                                            v-if="editSelected==1"
                                                            :items="blogs"
                                                            item-text="title"
                                                            item-value="id"
                                                            v-model="selectedMenu.blog_id"
                                                            label="Select Blog to add"
                                                            outlined
                                                            densed
                                                    ></v-select>

                                                    <treeselect
                                                            v-if="editSelected==2"
                                                            :id="'blog_category_edit'"
                                                            class="mb-3"
                                                            :multiple="false"
                                                            :options="blogCategories"
                                                            :default-expand-level="1"
                                                            :sort-value-by="sortValueBy"
                                                            placeholder="Choose Catagory"
                                                            v-model="selectedMenu.blog_category_id"
                                                            :value-consists-of="valueConsistsOf"
                                                            :disable-branch-nodes="false"
                                                    />

                                                    <treeselect
                                                            v-if="editSelected==3"
                                                            :id="'catalog_edit'"
                                                            class="mb-3"
                                                            :multiple="false"
                                                            :options="catalogsOptions"
                                                            :default-expand-level="1"
                                                            :sort-value-by="sortValueBy"
                                                            placeholder="Choose catalogs"
                                                            v-model="selectedMenu.shop_category_id"
                                                            :value-consists-of="valueConsistsOf"
                                                            :disable-branch-nodes="false"
                                                            outlined
                                                            densed
                                                    />
                                                    <v-text-field
                                                            v-model="selectedMenu.title"
                                                            outlined
                                                            densed
                                                            label="Menu Title"
                                                    ></v-text-field>
                                                    <v-text-field
                                                            v-if="editSelected==4"
                                                            v-model="selectedMenu.link"
                                                            outlined
                                                            densed
                                                            label="Menu Link"
                                                    ></v-text-field>

                                                    <v-select
                                                            v-model="selectedMenu.item_for"
                                                            :items="menu_types"
                                                            label="Location"
                                                            densed
                                                            outlined
                                                    ></v-select>
                                                    <v-radio-group v-model="selectedMenu.new_tab" outlined dense>
                                                        <template v-slot:label>
                                                            <div>
                                                                <strong>Tab Control</strong>
                                                            </div>
                                                        </template>
                                                        <v-radio :value="0">
                                                            <template v-slot:label>
                                                                <div>Open in same tab</div>
                                                            </template>
                                                        </v-radio>
                                                        <v-radio :value="1">
                                                            <template v-slot:label>
                                                                <div>Open in new tab</div>
                                                            </template>
                                                        </v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions class="justify-end">
                                            <v-btn color="purple" @click="reset" class="text-white">Back</v-btn>
                                            <v-btn color="primary" @click="updateMenuItem">Update to menu</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    <v-card raised v-else>
                                        <v-card-title>
                                            <span class="headline">Add Menu Item</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" md="4" sm="12">
                                                        <v-list two-line>
                                                            <v-list-item-group v-model="selected"
                                                                               active-class="active">
                                                                <template v-for="(item, index) in options">
                                                                    <v-list-item :key="index"
                                                                                 @click="changeType(item.value)">
                                                                        <template v-slot:default="{  }">
                                                                            <v-list-item-content>
                                                                                <v-list-item-title
                                                                                        v-text="item.title"></v-list-item-title>
                                                                            </v-list-item-content>
                                                                        </template>
                                                                    </v-list-item>

                                                                    <!-- <v-divider v-if="index + 1 < options.length" :key="index"></v-divider> -->
                                                                </template>
                                                            </v-list-item-group>
                                                        </v-list>
                                                    </v-col>
                                                    <v-col cols="12" md="8" sm="12">
                                                        <v-select
                                                                v-if="selected==0"
                                                                :items="pages"
                                                                item-text="title"
                                                                item-value="id"
                                                                return-object
                                                                @change="setNewMenu(newMenuType)"
                                                                v-model="newMenuType"
                                                                label="Select Page to add"
                                                                outlined
                                                                densed
                                                        ></v-select>
                                                        <v-select
                                                                v-if="selected==1"
                                                                :items="blogs"
                                                                return-object
                                                                @change="setNewMenu(newMenuType)"
                                                                item-text="title"
                                                                item-value="id"
                                                                v-model="newMenuType"
                                                                label="Select Blog to add"
                                                                outlined
                                                                densed
                                                        ></v-select>

                                                        <treeselect
                                                                v-if="selected==2"
                                                                class="mb-3"
                                                                :id="'blog_category_add'"
                                                                :multiple="false"
                                                                :options="blogCategories"
                                                                :default-expand-level="1"
                                                                :sort-value-by="sortValueBy"
                                                                placeholder="Choose Catagory"
                                                                @input="setNewMenu(newMenuType)"
                                                                v-model="selectedBlocCategoryId"
                                                                :value-consists-of="valueConsistsOf"
                                                                :disable-branch-nodes="false"
                                                        />
                                                        <!--                                                        <cascader-->
                                                        <!--                                                            style="width:100%; margin-buttom:50px;"-->
                                                        <!--                                                            size="large"-->
                                                        <!--                                                            :options="blogCategories"-->
                                                        <!--                                                            v-model="selected"-->
                                                        <!--                                                            :fieldNames="{label: 'title', value: 'id', children: 'children'}"-->
                                                        <!--                                                            placeholder="Select parent category"-->
                                                        <!--                                                            change-on-select-->
                                                        <!--                                                        />-->
                                                        <treeselect
                                                                v-if="selected==3"
                                                                :id="'catalog_add'"
                                                                class="mb-3"
                                                                :multiple="false"
                                                                :options="catalogsOptions"
                                                                :default-expand-level="1"
                                                                :sort-value-by="sortValueBy"
                                                                placeholder="Choose catalogs"
                                                                v-model="selectedCatalogId"
                                                                @input="setNewMenu(newMenuType)"
                                                                :value-consists-of="valueConsistsOf"
                                                                :disable-branch-nodes="false"
                                                        />
                                                        <v-text-field
                                                                @input="slugify(newMenu.title)"
                                                                v-model="newMenu.title"
                                                                outlined
                                                                densed
                                                                label="Menu Name"
                                                        ></v-text-field>
                                                        <v-text-field
                                                                v-if="selected==4"
                                                                v-model="newMenu.link"
                                                                outlined
                                                                densed
                                                                label="Link"
                                                        ></v-text-field>

                                                        <v-select
                                                                v-model="newMenu.item_for"
                                                                :items="menu_types"
                                                                outlined
                                                                label="Menu Location"
                                                                densed
                                                        ></v-select>

                                                        <v-radio-group v-model="newMenu.new_tab" outlined dense>
                                                            <template v-slot:label>
                                                                <div>
                                                                    <strong>Tab Control</strong>
                                                                </div>
                                                            </template>
                                                            <v-radio :value="0">
                                                                <template v-slot:label>
                                                                    <div>Open link in same tab</div>
                                                                </template>
                                                            </v-radio>
                                                            <v-radio :value="1">
                                                                <template v-slot:label>
                                                                    <div>Open link in new tab</div>
                                                                </template>
                                                            </v-radio>
                                                        </v-radio-group>
                                                    </v-col>
                                                </v-row>
                                                <div class="form-block">
                                                    <v-card-text></v-card-text>
                                                </div>
                                                <!-- <pre>{{attribute_object}}</pre> -->
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                    class="btn-save-popup text-white"
                                                    @click.prevent="addMenu"
                                            >Add to menu
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-app>
                            </div>
                        </div>
                    </template>
                </KTPortlet>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import WebsitePage from "@/services/Websites/WebsitePageService";
    import WebsiteMenu from "@/services/Websites/WebsiteMenuService";
    import WebsiteMenuItem from "@/services/Websites/WebsiteMenuItemService";
    import WebsiteBlogCategory from "@/services/Websites/WebsiteBlogCategoryService";
    import WebsiteBlog from "@/services/Websites/WebsiteBlogService";
    import ProductCatalogRootService from "@/services/store/ProductCatalogRootService";
    import {VueNestable, VueNestableHandle} from "vue-nestable";
    import Cascader from "ant-design-vue/lib/cascader";
    import "ant-design-vue/dist/antd.css";
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";

    const WebsitePageService = new WebsitePage();

    const WebsiteMenuService = new WebsiteMenu();

    const WebsiteMenuItemService = new WebsiteMenuItem();

    const WebsiteBlogCategoryService = new WebsiteBlogCategory();

    const WebsiteBlogService = new WebsiteBlog();

    const rootCatalogService = new ProductCatalogRootService();

    export default {
        name: "website-menu",
        components: {
            KTPortlet,
            Cascader, VueNestable,
            VueNestableHandle,
            treeselect: Treeselect
        },
        validations: {
            page: {
                title: {required},
                slug: {required}
            }
        },
        data() {
            return {
                selected: 0,
                editSelected: 0,
                selectedMenu: null,
                newMenuType: null,
                header_menu: null,
                footer_menu: null,
                loading: false,
                blogs: [],
                blogCategories: [],
                tab: null,
                menu_types: ["header", "footer"],
                options: [
                    {title: "Page", value: "page"},
                    {title: "Blog Post", value: "blog"},
                    {title: "Category", value: "blog_category"},
                    {title: "Catalog", value: "catalog"},
                    {title: "Custom Link", value: "custom_link"}
                ],
                newMenu: {
                    parent_id: null,
                    page_id: null,
                    blog_id: null,
                    blog_category_id: null,
                    shop_category_id: null,
                    product_id: null,
                    title: null,
                    link: null,
                    position: null,
                    depth: null,
                    type: "page",
                    is_active: 1,
                    new_tab: 0,
                    menu_type: null,
                    item_for: "header"
                },
                selectedCatalogId: null,
                selectedBlocCategoryId: null,
                pages: [],
                addMenudialog: false,
                menuItem: [
                    {
                        id: null,
                        menu_id: null,
                        parent_id: null,
                        title: null,
                        link: null,
                        position: null,
                        depth: null,
                        type: null,
                        is_active: null,
                        new_tab: 0,
                        menu_type: null,
                        created_at: null
                    }
                ],
                sortValueBy: "ORDER_SELECTED",
                valueConsistsOf: 'ALL_WITH_INDETERMINATE',
                catalogsOptions: [],
                nestableHeaderItems: [],
                nestableFooterItems: []
            };
        },

        methods: {
            beforeMove({dragItem, pathFrom, pathTo}) {
                // Item 4 can not be nested more than one level
                if (dragItem.id === 4) {
                    return pathTo.length === 1;
                }
                // All other items can be
                return true;
            },
            addMenuForm() {
                this.selectedMenu = null;
            },
            opoenDialog() {
                this.addMenudialog = true;
            },
            closeDialog() {
                this.addMenudialog = false;
            },
            edit(item) {
                switch (item.menu_type) {
                    case "page":
                        this.editSelected = 0;
                        break;
                    case "blog":
                        this.editSelected = 1;
                        break;
                    case "blog_category":
                        this.editSelected = 2;
                        break;
                    case "catalog":
                        this.editSelected = 3;
                        break;
                    case "custom_link":
                        this.editSelected = 4;
                        break;
                    default:
                        this.editSelected = 0;
                }
                this.selectedMenu = item;
            },
            changeType(type) {
                this.newMenu.menu_type = type;
                this.newMenu.title = '';
            },
            setNewMenu(item) {
                if (this.newMenu.menu_type == "blog_category") {
                    this.newMenu.blog_category_id = this.selectedBlocCategoryId;
                } else if (this.newMenu.menu_type == "catalog") {
                    this.newMenu.shop_category_id = this.selectedCatalogId;
                } else if (this.newMenu.menu_type == "blog") {
                    this.newMenu.blog_id = item.id;
                } else {
                    this.newMenu.page_id = item.id;
                }
                this.newMenu.title = item.title;
                this.newMenuType = null;
            },
            changeSelectedType(type) {
                this.selectedMenu.menu_type = type;
            },
            updateMenuItem() {
                this.selectedMenu.new_tab = Number(this.selectedMenu.new_tab);
                WebsiteMenuItemService.update(this.siteUrl, this.selectedMenu).then(
                    res => {
                        this.$snotify.success("Updated  Successfully!");
                        this.getMenu();
                        this.closeDialog();
                    }
                );
            },
            deleteImg(menuId, id) {

                this.$confirm(
                    {
                        message: `Confirm delete menu item ? `,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteMenuItemService.delete(this.siteUrl, menuId, id).then(
                                    res => {
                                        this.$snotify.success("Deleted");
                                        this.getMenu();
                                    }
                                );
                            }
                        }
                    }
                )


            },

            getCatalogs() {
                rootCatalogService
                    .getAll()
                    .then(response => {
                        if (response.data.length > 0) {
                            response.data.forEach(ele => {
                                ele.catalogs.forEach(e => {
                                    this.catalogsOptions.push(e);
                                });
                            });
                        }
                    })
                    .catch(error => {
                    });
            },
            getMenu() {
                WebsiteMenuService.getMenus(this.siteUrl)
                    .then(res => {
                        let data = res.data;
                        data.forEach(element => {
                            if (element.title === "header") {
                                this.header_menu = element;
                            } else {
                                this.footer_menu = element;
                            }
                        });
                    })
                    .catch(error => {
                    });
                WebsiteMenuItemService.getMenuItems(this.siteUrl)
                    .then(res => {
                        this.nestableHeaderItems = res.data.header;
                        this.nestableFooterItems = res.data.footer;
                    })
                    .catch(error => {
                    });
            },
            getBlogs() {
                WebsiteBlogService.paginate(this.siteUrl).then(res => {
                    this.blogs = res.data.blogs;
                    // this.pages = res.data.data;
                });
            },
            getBlogCategory() {
                WebsiteBlogCategoryService.paginate(this.siteUrl).then(res => {
                    this.blogCategories = res.data.data;
                    // this.pages = res.data.data;
                });
            },
            addMenu() {
                this.newMenu.new_tab = Number(this.newMenu.new_tab);
                WebsiteMenuItemService.create(this.siteUrl, this.newMenu).then(res => {
                    this.newMenu = {
                        parent_id: null,
                        page_id: null,
                        blog_id: null,
                        blog_category_id: null,
                        shop_category_id: null,
                        product_id: null,
                        title: null,
                        link: null,
                        position: null,
                        depth: null,
                        type: "page",
                        is_active: 1,
                        new_tab: 0,
                        menu_type: null,
                        item_for: "header"
                    };
                    this.$snotify.success("Menu added");
                    this.getMenu();
                    this.closeDialog();
                });
            },
            getPages(type = "default", flag = null) {
                WebsitePageService.paginate(this.siteUrl, type, flag)
                    .then(res => {
                        this.pages = res.data.pages;
                    })
                    .catch(err => {
                    });
            },
            slugify(text) {
                this.newMenu.link = text
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace(/[^\w-]+/g, "");
            },
            sortNow(menuId, nestableItems) {
                WebsiteMenuItemService.sort(this.siteUrl, menuId, nestableItems)
                    .then(response => {
                        if (response.data.status === "OK") {
                            this.$snotify.success("Sorted");
                            this.getMenu();
                        } else {
                            this.$snotify.error("Error");
                        }
                    })
                    .catch(error => {
                    });
            },
            reset() {
                this.selectedMenu = null;
            },
            restoreItem(menuId, item) {
                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteMenuItemService.restore(
                                    this.siteUrl,
                                    menuId,
                                    item.id
                                )
                                    .then(response => {
                                        this.$snotify.success("Item succesfully restored");
                                        this.getMenu();
                                    })
                                    .catch(error => {
                                    });
                            }
                        }
                    }
                )


            }
        },
        computed: {
            siteUrl() {
                return this.$route.params.domainname;
            }
        },
        mounted() {
            this.getPages();
            this.getMenu();
            this.getBlogCategory();
            this.getBlogs();
            this.getCatalogs();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Manage Pages", route: "/websites"}
            ]);
        }
    };
</script>


<style lang="scss" scope>
    /*
    * Style for nestable
    */

    .menuable__content__active {
        top: 15% !important;
        left: 37% !important;
    }

    .nestable {
        position: relative;
    }

    .nestable-rtl {
        direction: rtl;
    }

    .nestable .nestable-list {
        margin: 0;
        padding: 0 0 0 40px;
        list-style-type: none;
    }

    .nestable-rtl .nestable-list {
        padding: 0 40px 0 0;
    }

    .nestable > .nestable-list {
        padding: 0;
    }

    .nestable-item,
    .nestable-item-copy {
        margin: 10px 0 0;
    }

    .nestable-item:first-child,
    .nestable-item-copy:first-child {
        margin-top: 0;
    }

    .nestable-item .nestable-list,
    .nestable-item-copy .nestable-list {
        margin-top: 10px;
    }

    .nestable-item {
        position: relative;
    }

    .nestable-item.is-dragging .nestable-list {
        pointer-events: none;
    }

    .nestable-item.is-dragging * {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    .nestable-item.is-dragging:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(106, 127, 233, 0.274);
        border: 1px dashed rgb(73, 100, 241);
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }

    .nestable-drag-layer {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        pointer-events: none;
    }

    .nestable-rtl .nestable-drag-layer {
        left: auto;
        right: 0;
    }

    .nestable-drag-layer > .nestable-list {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        background-color: rgba(106, 127, 233, 0.274);
    }

    .nestable-rtl .nestable-drag-layer > .nestable-list {
        padding: 0;
    }

    .nestable [draggable="true"] {
        cursor: move;
    }

    .nestable-handle {
        display: inline;
    }

    .nestable-item .btn-group {
        float: right;
    }

    .nestable-item .nestable-item-content,
    .kt-portlet__body ol.nestable-list .nestable-item-content {
        color: black;
        font-weight: bold;
        padding: 1rem 2rem;
        border-radius: 5px;
        background: #eceff0;
    }

    .nestable-handle {
        margin-right: 10px;
        cursor: grabbing !important;
    }

    .nestable-handle span {
        font-weight: bold !important;
    }
</style>
